import { call, put, select, takeLatest, all } from "redux-saga/effects"
import * as patientActions from "library/common/actions/patient"

import {
  overwritePatient,
  requestAddNewPatient,
  requestDeletePatient,
  requestExternalPatient,
  requestGetActivePatient,
  requestGetAllPatients,
  requestLinkPatient,
} from "library/services/patientApi"
import {
  ActivePatientResult,
  ActivePatientServerResult,
  IActivePatientListItem,
  PatientListServerResult,
  PatientMatch,
  PatientTypes,
  RadiographSet,
  Status,
} from "../types/patientTypes"
import { setPatientListResult } from "../actions/patient"
import { closeModal, openModal } from "../actions/modal"
import { Modals } from "../reducers/modalsReducer"
import { Kind } from "../types/serverDataTypes"
import { transformDate } from "library/utilities/date"
import { ResultStatus } from "../types/dataStructureTypes"
import { patientFileUrl } from "library/utilities/urls"
import { setPatientFileBreadcrumb } from "../actions/breadcrumbs"
import { history } from "core/store/configureStore"
import {
  getActivePatientListItem,
  getActivePatientResult,
  getActiveRadiographSet,
  getIsAccordionOpen,
  getIsEditSetActive,
} from "../selectors/patient"
import { setOpenToast } from "../actions/toast"
import { ToastType } from "../types/toast"
import { getContextQueryParams } from "../selectors/user"
import { ContextQuery } from "../types/userTypes"
import { IAnalysisResult } from "./imageSaga"
import { requestImageAnalysis } from "library/services/imageApis"
import { AllConfirmedAnnotations } from "../types/adjustmentTypes"
import { getIsOwner } from "../selectors/serverData"
import i18next from "i18next"
import {
  getFeatureShowSSO,
  getShowSetupInOnboarding,
} from "../selectors/features"
import { setSavingResultStatus } from "../actions/saving"
import { updateRadiographSet } from "../actions/xrays"

// Sort order for patient list
const statusIndexing = [
  Status.New,
  Status.Uploaded,
  Status.Viewed,
  Status.Confirmed,
]

const status = (patientData: {
  imageDate: string
  report_submitted?: string
  reportDate?: string
  viewed: boolean
  statusTag?: number
}) => {
  // if the backend computed the status for us, use it:
  if (patientData.statusTag) {
    return statusIndexing[patientData.statusTag - 1]
  }

  const oneDayAgo = new Date().getTime() - 24 * 60 * 60 * 1000

  switch (true) {
    case !!patientData.reportDate || !!patientData.report_submitted:
      return Status.Confirmed
    case patientData.viewed:
      return Status.Viewed
    case Date.parse(patientData.imageDate) > oneDayAgo:
      return Status.New
    default:
      return Status.Uploaded
  }
}
function* getPatientSaga() {
  yield put(patientActions.setPatientListResultStatus(ResultStatus.Loading))

  const params: ContextQuery = yield select(getContextQueryParams)
  try {
    const { data }: PatientListServerResult = yield call(
      requestGetAllPatients,
      params
    )
    const featureShowSSO: boolean = yield select(getFeatureShowSSO)
    const showSetupInOnboarding: boolean = yield select(
      getShowSetupInOnboarding
    )

    if (featureShowSSO && data.length === 0 && showSetupInOnboarding) {
      yield put(openModal({ openedModal: Modals.NewUserOnboardingModal }))
    }

    const restructuredData = data.map((d) => {
      return {
        ...d,
        id: d.patientUuid || "", // For the table list we require a unique id field of type string.
        imageDate: transformDate(d.imageDate) || "",
        xrays: Object.values(d.modalities).reduce(
          (a: number, b: number) => a + b,
          0
        ),
        status: status(d),
      }
    })

    yield put(
      setPatientListResult({
        patientList: restructuredData,
        resultStatus: ResultStatus.Success,
      })
    )
  } catch (error) {
    yield put(patientActions.setPatientListResultStatus(ResultStatus.Error))
    console.error("error loading patients", error)
  }
}

function* requestPatientSaga({
  payload,
}: ReturnType<typeof patientActions.requestPatient>) {
  const params: ContextQuery = yield select(getContextQueryParams)
  const isValidUuid =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
      payload
    ) || payload === "unassigned"

  if (!isValidUuid) {
    yield put(
      setOpenToast({
        type: ToastType.InvalidUuid,
        notificationType: "error",
        message: i18next.t("app.toast.invalid_uuid"),
      })
    )
    return history.push("/patients")
  }

  yield put(patientActions.setActivePatientResultStatus(ResultStatus.Loading))
  const activeRadiographSet: RadiographSet | null = yield select(
    getActiveRadiographSet
  )
  const isOwner: boolean = yield select(getIsOwner)
  const activePatientListItem: IActivePatientListItem = yield select(
    getActivePatientListItem
  )
  const isAccordionOpen: boolean = yield select(getIsAccordionOpen)
  const isEditSetActive: boolean = yield select(getIsEditSetActive)
  try {
    const { data }: ActivePatientServerResult = yield call(
      requestGetActivePatient,
      payload,
      params
    )

    const activePatientImagesData =
      data.images?.map((d) => {
        return {
          ...d,
          imageDate: transformDate(
            d.imageDate || d.imageMetadata.analysisDate
          )!, // fail-safe in case the imageDate is empty
          generalComment: (d.generalComment || "").trim(), // trim whitespace, ensure not-null
          addedComments: (d.addedComments || []).map((c) => ({
            ...c,
            comment: (c.comment || "").trim(),
          })),
          notes: (d.notes || []).map((c) => ({
            ...c,
            text: (c.text || "").trim(),
          })),
          imageMetadata: {
            ...d.imageMetadata,
            kind: d.imageMetadata.kind || Kind.Unknown, // Backend returns no kind when it is unknown
            isOwner: isOwner,
          },
          status: status(d),
        }
      }) || []

    const activeRadiographSetStatuses = (radiographs: string[]) => {
      return [
        ...new Set(
          data.images
            ?.filter((a) => radiographs.includes(a.resultId || ""))
            .map((a) => status(a))
        ),
      ]
    }

    // Get the latest radiographs in the active radiograph set from the backend and set the statuses
    const activeRadiographSetWithFirstImage: RadiographSet | null =
      data.radiographSets
        ?.filter((r) => r.id === activeRadiographSet?.id)
        .map((a) => ({
          ...a,
          statuses: activeRadiographSetStatuses(a.radiographs),
          // Keep the activeSetImage if it exists. This allows us to keep the active image when changing orientation changing image type
          ...(activeRadiographSet?.activeSetImage && {
            activeSetImage: activeRadiographSet.activeSetImage,
          }),
        }))
        .shift() || null

    const setPositions = (set: RadiographSet) => ({
      ...set,
      // If the positions are not set, set them to the index of the radiographs
      positions: set.positions,
    })

    yield put(
      patientActions.setActivePatientResult({
        images: activePatientImagesData,
        resultStatus: ResultStatus.Success,
        patient: data.patient,
        links: data.links,
        radiographSets:
          data.radiographSets?.filter(
            (s) => s.radiographs?.length && s.radiographs.every((val) => !!val)
          ) || [].map((r) => setPositions(r)),

        activeRadiographSet: activeRadiographSetWithFirstImage
          ? activeRadiographSetWithFirstImage
          : null,
        activePatientListItem: activePatientListItem,
        isAccordionOpen: isAccordionOpen,
        isEditSetActive: isEditSetActive,
      })
    )

    // Handle showing of patient breadcrumb once data is obtained
    yield put(
      setPatientFileBreadcrumb(patientFileUrl(data.patient.patientUuid))
    )
  } catch (error) {
    yield put(patientActions.setActivePatientResultStatus(ResultStatus.Error))
    console.error(error)
  }
}

function* getConfirmedAnnotations({
  payload: id,
}: ReturnType<typeof patientActions.getConfirmedAnnotations>) {
  const params: ContextQuery = yield select(getContextQueryParams)
  yield put(
    patientActions.setConfirmedAnnotations({
      status: ResultStatus.Loading,
    })
  )
  try {
    const { data }: IAnalysisResult = yield call(requestImageAnalysis, id, {
      ...params,
      mode: "combined",
      masks: false,
    })

    const consideredTypes = [
      AllConfirmedAnnotations.Caries,
      AllConfirmedAnnotations.Apical,
      AllConfirmedAnnotations.Calculus,
    ] as const

    const annotations = consideredTypes
      .map((type) => ({
        type,
        teeth: new Set(
          data[type]?.map((d) => d.toothName).filter(Boolean) || []
        ).size, // count the number of different affected teeth for this type
      }))
      .filter((i) => i.teeth > 0)

    const bonelossDetections = data?.boneLoss?.annotations
    const boneloss =
      data.forms?.boneLoss.maxBoneLossPercent ||
      (!!bonelossDetections
        ? Math.max(
            ...bonelossDetections?.map((d) => Math.max(d.d || 0, d.m || 0))
          )
        : undefined)

    yield put(
      patientActions.setConfirmedAnnotations({
        annotations,
        boneloss,
        status: ResultStatus.Success,
      })
    )
  } catch (error) {
    yield put(
      patientActions.setConfirmedAnnotations({
        status: ResultStatus.Error,
      })
    )
    console.error("error loading confirmed Annotations", error)
  }
}

function* overwritePatientSaga({
  payload,
}: ReturnType<typeof patientActions.overwritePatient>) {
  yield put(patientActions.setPatientResultStatus(ResultStatus.Loading))
  const activePatientResult: ActivePatientResult = yield select(
    getActivePatientResult
  )

  try {
    const { data } = yield call(overwritePatient, payload)
    yield put(
      patientActions.setActivePatientResult({
        ...activePatientResult,
        patient: { ...data.patient, resultStatus: ResultStatus.Success },
      })
    )
  } catch (error) {
    yield put(patientActions.setPatientResultStatus(ResultStatus.Error))
    console.error("error overwriting patient", error)
  }
}

function* addNewPatientSaga({
  payload,
}: ReturnType<typeof patientActions.addNewPatient>) {
  yield put(setSavingResultStatus(ResultStatus.Loading))
  try {
    const { data } = yield call(requestAddNewPatient, payload)
    yield put(setSavingResultStatus(ResultStatus.Success))
    yield put(closeModal())
    history.push(`/patients/${data.patient.patientUuid}`)
  } catch (error) {
    yield put(setSavingResultStatus(ResultStatus.Error))
    console.error("error adding new patient", error)
  }
}

function* deletePatientSaga({
  payload,
}: ReturnType<typeof patientActions.deletePatient>) {
  try {
    yield call(requestDeletePatient, payload)
    history.push("/patients")
  } catch (error) {
    console.error("error deleting patient", error)
  }
}

function* getExternalPatientSaga({
  payload,
}: ReturnType<typeof patientActions.getExternalPatient>) {
  const params: ContextQuery = yield select(getContextQueryParams)

  try {
    const { data }: { data: PatientMatch } = yield call(
      requestExternalPatient,
      payload,
      params
    )

    // if the backend could not retrieve the data, don't enable matching mode
    const matchingMode = data.status !== "itero-unavailable" && !data.link
    yield put(patientActions.setIsPatientMatchingMode(matchingMode))
    yield put(patientActions.setPatientMatch(matchingMode ? data : null))

    // we need to keep the original external patient context data
    // this is used to validate the availability of the iTero snapshot feature
    yield put(patientActions.setExternalPatientContext(data))
    history.push(data.link ? patientFileUrl(data.link) : "/patients")
  } catch (error) {
    console.error(error)
    history.push("/patients")
  }
}

function* linkPatientSaga({
  payload,
}: ReturnType<typeof patientActions.linkPatient>) {
  const params: ContextQuery = yield select(getContextQueryParams)
  try {
    const { data }: { data: PatientMatch } = yield call(
      requestLinkPatient,
      payload.id,
      payload.matchingPatientId,
      params
    )

    yield put(
      setOpenToast({
        type: ToastType.LinkedPatients,
        notificationType: "success",
        message: "app.toast.linked",
        patientMatch: data,
      })
    )
    yield put(
      patientActions.setPatientMatch(payload.matchingPatientId ? null : data)
    )
    yield put(
      patientActions.setIsPatientMatchingMode(!payload.matchingPatientId)
    )
    // When linking patients, isPatientMatchingMode should be off and when unlinking, we re-route to /patients
    if (payload.matchingPatientId) {
      history.push(patientFileUrl(payload.matchingPatientId))
    } else {
      history.push("/patients")
    }
  } catch (error) {
    console.error(error)
  }
}

function* relinkPatientSaga({
  payload,
}: ReturnType<typeof patientActions.relinkPatient>) {
  const params: ContextQuery = yield select(getContextQueryParams)
  const { id, matchingPatientId, linkedMeta } = payload

  try {
    // Unlink all linked patients
    yield all(
      (linkedMeta || []).map((l) =>
        call(requestLinkPatient, l.externalId, null, params)
      )
    )

    // Link the new patient
    const { data }: { data: PatientMatch } = yield call(
      requestLinkPatient,
      id,
      matchingPatientId,
      params
    )

    yield put(
      setOpenToast({
        type: ToastType.LinkedPatients,
        notificationType: "success",
        message: "app.toast.linked",
        patientMatch: data,
      })
    )
    yield put(patientActions.setPatientMatch(data))
    yield put(
      patientActions.setIsPatientMatchingMode(!payload.matchingPatientId)
    )
    history.push(patientFileUrl(payload.matchingPatientId))
  } catch (error) {
    console.error(error)
  }
}

function* removeRadiographFromSetSaga({
  payload: { isAddingRadiographToSet },
}: ReturnType<typeof patientActions.removeRadiographFromSet>) {
  const activeRadiographSet: RadiographSet | null = yield select(
    getActiveRadiographSet
  )

  if (!activeRadiographSet || isAddingRadiographToSet) return

  yield put(
    updateRadiographSet({
      id: activeRadiographSet.id,
      set: {
        changes: activeRadiographSet.changes,
        radiographs: activeRadiographSet.radiographs,
        template: activeRadiographSet.template,
      },
    })
  )
}

export default function* patientSaga() {
  yield takeLatest(PatientTypes.GetPatientsFromServer, getPatientSaga)
  yield takeLatest(PatientTypes.GetExternalPatient, getExternalPatientSaga)
  yield takeLatest(PatientTypes.RequestPatient, requestPatientSaga)
  yield takeLatest(PatientTypes.OverwritePatient, overwritePatientSaga)
  yield takeLatest(PatientTypes.LinkPatient, linkPatientSaga)
  yield takeLatest(PatientTypes.RelinkPatient, relinkPatientSaga)
  yield takeLatest(
    PatientTypes.GetConfirmedAnnotations,
    getConfirmedAnnotations
  )
  yield takeLatest(PatientTypes.AddNewPatient, addNewPatientSaga)
  yield takeLatest(PatientTypes.DeletePatient, deletePatientSaga)
  yield takeLatest(
    PatientTypes.RemoveRadiographFromSet,
    removeRadiographFromSetSaga
  )
}
