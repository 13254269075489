import { createAction } from "typesafe-actions"

import {
  License,
  LogoutReason,
  NumberingSystem,
  Role,
  UserTypes,
} from "../types/userTypes"
import { RegulatoryMarket } from "../types/serverDataTypes"
import { ResultStatus } from "../types/dataStructureTypes"

export interface ActionLogin {
  isSSO: boolean
  username: string
  password?: string
  redirect_uri?: string
  directLoginUsername?: string
  isRefreshToken?: boolean
  advancedProvider?: boolean
}

export interface ActionResetPasswordFirstTime {
  newPassword: string
  fullName: string
  email: string
  telephoneNumber: string
  token: string
}

// Keep key-value pairs lowercase as it is used with state objects
export enum Theme {
  dentalxrai = "dentalxrai",
  carestream = "carestream",
}

export enum CoachMarkFeature {
  CariesPro = "cariesPro",
  AutoCariesPro = "autoCariesPro",
  BonelossPro = "bonelossPro",
  CalculusXrayImage = "calculusXrayImage",
  CalculusToothMap = "calculusToothMap",
  CalculusEditView = "calculusEditView",
  CariesOnOpg = "cariesOnOpg",
}

export enum CoachMarkIndex {
  First = 1,
}

export type CoachMarkRecord = Record<CoachMarkFeature, CoachMarkIndex>

export type GeneratedToken = {
  token: string
  sessionId?: string
}

export interface ActionUserInfo {
  fullName?: string
  email?: string
  SSO?: boolean
  knownHandlers?: string[]
  cariesPro?: boolean
  bonelossPro?: boolean
  boneLossLite?: boolean
  uploadsRemaining?: number
  whatsNew?: string
  lastCoachMarks?: CoachMarkRecord
  theme?: Theme
  calculus?: boolean
  nervus?: boolean
  impacted?: boolean
  license?: License
  licenceExpire?: string
  showDrawingMode?: boolean
  boneLossOnly?: boolean
  mustResetPassword?: boolean
  modalities?: string[]
  numberingSystem?: NumberingSystem
  toothBasedPeri?: boolean
  role?: Role
  companyId?: string
  doctorId?: string
  isIteroUser?: boolean
  isIteroMidcUser?: boolean
  isIteroScannerUser?: boolean
  installerByDefault?: boolean
  hidePatientId?: boolean
  showAlfaDocs?: boolean
  alfaDocsApiKey?: string
  regulatoryMarket?: RegulatoryMarket
  username?: string
  planType?: string | undefined
  defaultCompanyId?: string
}

export interface ActionLastCoachMark {
  coachMarkFeature: CoachMarkFeature
  coachMarkIndex: CoachMarkIndex
}

export const logOutAction = createAction(UserTypes.LogoutAction)<LogoutReason>()
export const resetErrorAction = createAction(UserTypes.ResetErrorAction)()
export const loginAction = createAction(UserTypes.LoginAction)()
export const loginActionSuccess = createAction(
  UserTypes.LoginActionSuccess
)<ActionLogin>()
export const loginActionError = createAction(
  UserTypes.LoginActionError
)<number>()
export const loginActionWithData = createAction(
  UserTypes.LoginActionWithData
)<ActionLogin>()
export const resetPasswordAction = createAction(UserTypes.ResetPasswordAction)<{
  oldPassword: string
  newPassword: string
}>()
export const newPasswordErrorAction = createAction(
  UserTypes.NewPasswordErrorAction
)<string>()
export const oldPasswordErrorAction = createAction(
  UserTypes.OldPasswordErrorAction
)<string>()
export const setResetPasswordStatus = createAction(
  UserTypes.SetResetPasswordStatus
)<ResultStatus>()
export const setMustResetPassword = createAction(
  UserTypes.SetMustResetPasswordAction
)<boolean>()
export const resetPasswordFirstTimeAction = createAction(
  UserTypes.ResetPasswordFirstTimeAction
)<ActionResetPasswordFirstTime>()
export const getUserName = createAction(UserTypes.GetUserName)()
export const setUserResultStatus = createAction(
  UserTypes.SetUserResultStatus
)<ResultStatus>()
export const setUserInfo = createAction(UserTypes.SetUserInfo)<ActionUserInfo>()
export const setServerError = createAction(UserTypes.SetServerError)<string>()
export const setServerErrorMessage = createAction(
  UserTypes.SetServerErrorMessage
)<string>()
export const setHandlerName = createAction(UserTypes.SetHandlerName)<string>()
export const setHandlerHash = createAction(UserTypes.SetHandlerHash)<string>()
export const submitHandlerHash = createAction(UserTypes.SubmitHandlerHash)()
export const toggleCariesPro = createAction(UserTypes.ToggleCariesPro)()
export const toggleBonelossPro = createAction(UserTypes.ToggleBonelossPro)()
export const toggleDrawingMode = createAction(UserTypes.ToggleDrawingMode)()
export const setBonelossPro = createAction(UserTypes.SetBonelossPro)<boolean>()
export const setWhatsNew = createAction(UserTypes.SetWhatsNew)()
export const generateToken = createAction(UserTypes.GenerateToken)()
export const setGeneratedToken = createAction(
  UserTypes.SetGeneratedToken
)<GeneratedToken>()

export const setLastCoachMarks = createAction(
  UserTypes.SetLastCoachMarks
)<ActionLastCoachMark>()
export const setImpersonate = createAction(UserTypes.SetImpersonate)<string>()
export const setAlfaDocsApiKey = createAction(
  UserTypes.SetAlfaDocsApiKey
)<string>()
export const setUserIntegrationResultStatus = createAction(
  UserTypes.SetUserIntegrationResultStatus
)<ResultStatus>()
export const setIsOpenUserSettingsModal = createAction(
  UserTypes.SetIsOpenUserSettingsModal
)<boolean>()
export const setShowSmallScreenNotification = createAction(
  UserTypes.SetShowSmallScreenNotification
)<boolean>()
export const setIsPendoRunning = createAction(
  UserTypes.SetIsPendoRunning
)<boolean>()
