import { Kind } from "./serverDataTypes"

export enum ImageTypes {
  LoadImage = "@@Image/LoadImage",
  LoadImageSuccess = "@@Image/LoadImageSuccess",
  ImageProcessingComplete = "@@Image/ImageProcessingComplete",
  LoadAnnotations = "@@Image/LoadAnnotations",
  LoadAnnotationsSuccess = "@@Image/LoadAnnotationsSuccess",
  SetShownRadiographAnnotations = "@@Image/SetShownRadiographAnnotations",
  SetInitialState = "@@Image/SetInitialState",
  RotateImage = "@@Image/RotateImage",
  SetAsNotProcessed = "@@Image/SetAsNotProcessed",
  ChangeRadiographType = "@@Image/ChangeRadiographType",
  UpdateOpenDateMs = "@@Image/UpdateOpenDateMs",
  LoadPdfReport = "@@Image/LoadPdfReport",
  LoadPdfReportSuccess = "@@Image/LoadPdfReportSuccess",
  OpenPdfReport = "@@Image/OpenPdfReport",
  SetShowImmediately = "@@Image.SetShowImmediately",
  ReanalyzeImage = "@@Image/ReanalyzeImage",
  RevertImage = "@@Image/RevertImage",
  SetAcceptMissingMetadata = "@@Image/SetAcceptMissingMetadata",
  SaveReport = "@@Image/SaveReport",
  CopyReportAsImage = "@@Image/CopyReportAsImage",
  CopyReportAsText = "@@Image/CopyReportAsText",
  SendReport = "@@Image/SendReport",
  SetImageResultStatus = "@@Image/SetImageResultStatus",
  SetIsReportSentToAlfaDocs = "@@Image/SetIsReportSentToAlfaDocs",
  SetIsActiveFmxImage = "@@Image/SetIsActiveFmxImage",
}

export type PdfReport = Readonly<{
  id: string
  isBoneLossPdf: boolean
}>

export type PdfReportData = Readonly<{
  pdfReportData: string
  textAnnotations: string
}>

export type PdfData = Readonly<{
  data: Readonly<{
    pdf: string
    text: string
  }>
}>

export type RotateData = Readonly<{
  imageId: string
  rotate: number
  isFlipped: boolean
}>

export type Position = Readonly<{
  x: number
  y: number
}>

export type RadiographType = Readonly<{
  id: string
  kind: Kind
}>

export type SendReport = Readonly<{
  id: string
  pdfReport: string
}>
