import { createAction } from "typesafe-actions"

import {
  ImageTypes,
  PdfReport,
  PdfReportData,
  RadiographType,
  RotateData,
  SendReport,
} from "library/common/types/imageTypes"
import { ResultStatus } from "../types/dataStructureTypes"

export const loadImage = createAction(ImageTypes.LoadImage)<string>()
export const loadImageSuccess = createAction(
  ImageTypes.LoadImageSuccess
)<string>()
export const imageProcessingComplete = createAction(
  ImageTypes.ImageProcessingComplete
)<string>()
export const loadAnnotations = createAction(
  ImageTypes.LoadAnnotations
)<string>()
export const loadAnnotationsSuccess = createAction(
  ImageTypes.LoadAnnotationsSuccess
)()
export const setShownRadiographAnnotations = createAction(
  ImageTypes.SetShownRadiographAnnotations
)<string>()
export const setInitialState = createAction(ImageTypes.SetInitialState)()
export const rotateImage = createAction(ImageTypes.RotateImage)<RotateData>()
export const setAsNotProcessed = createAction(ImageTypes.SetAsNotProcessed)()
export const changeRadiographType = createAction(
  ImageTypes.ChangeRadiographType
)<RadiographType>()
export const updateOpenDateMs = createAction(ImageTypes.UpdateOpenDateMs)()
export const loadPdfReport = createAction(ImageTypes.LoadPdfReport)<PdfReport>()
export const loadPdfReportSuccess = createAction(
  ImageTypes.LoadPdfReportSuccess
)<PdfReportData>()
export const openPdfReport = createAction(ImageTypes.OpenPdfReport)()
export const setShowImmediately = createAction(
  ImageTypes.SetShowImmediately
)<boolean>()
export const reanalyzeImage = createAction(ImageTypes.ReanalyzeImage)()
export const revertImage = createAction(ImageTypes.RevertImage)<number>()
export const setAcceptMissingMetadata = createAction(
  ImageTypes.SetAcceptMissingMetadata
)<boolean | null>()
export const saveReport = createAction(ImageTypes.SaveReport)()
export const copyReportAsImage = createAction(ImageTypes.CopyReportAsImage)()
export const copyReportAsText = createAction(ImageTypes.CopyReportAsText)()
export const sendReport = createAction(ImageTypes.SendReport)<SendReport>()
export const setImageResultStatus = createAction(
  ImageTypes.SetImageResultStatus
)<ResultStatus>()
export const setIsReportSentToAlfaDocs = createAction(
  ImageTypes.SetIsReportSentToAlfaDocs
)<boolean>()
