import { AllConfirmedAnnotations } from "./adjustmentTypes"
import { ResultStatus } from "./dataStructureTypes"
import { IMeta, INote, Kind } from "./serverDataTypes"

export enum PatientTypes {
  SetActivePatientResult = "@@Patient/SetActivePatientResult",
  GetPatientsFromServer = "@@Patient/GetPatientsFromServer",
  SetPatientListResult = "@@Patient/SetPatientListResult",
  RequestPatient = "@@Patient/RequestPatient",
  DeleteActivePatientXray = "@@Patient/DeleteActivePatientXray",
  SetInitialState = "@@Patient/SetInitialState",
  SetFilterValues = "@@Patient/SetFilterValues",
  SetPatientListResultStatus = "@@Patient/SetPatientListResultStatus",
  SetActivePatientResultStatus = "@@Patient/SetActivePatientResultStatus",
  SetPatientResultStatus = "@@Patient/SetPatientResultStatus",
  ClearActivePatientResult = "@@Patient/ClearActivePatientResult",
  OverwritePatient = "@@Patient/OverwritePatient",
  GetExternalPatient = "@@Patient/GetExternalPatient",
  SetPatientMatch = "@@Patient/SetPatientMatch",
  SetExternalPatientContext = "@@Patient/SetExternalPatientContext",
  LinkPatient = "@@Patient/LinkPatient",
  RelinkPatient = "@@Patient/RelinkPatient",
  SetIsPatientMatchingMode = "@@Patient/SetIsPatientMatchingMode",
  SetPatientListSorting = "@@Patient/SetPatientListSorting",
  SetActiveRadiographSet = "@@Patient/SetActiveRadiographSet",
  ReplaceRadiographSetImage = "@@Patient/ReplaceRadiographSetImage",
  SetPatientMetadata = "@@Patient/SetPatientMetadata",
  SetPatientImageMetadata = "@@Patient/SetPatientImageMetadata",
  GetConfirmedAnnotations = "@@Patient/GetConfirmedAnnotations",
  SetConfirmedAnnotations = "@@Patient/SetConfirmedAnnotations",
  SetActivePatientListItem = "@@Patient/SetActivePatientListItem",
  ToggleAccordion = "@@Patient/ToggleAccordion",
  SetActiveSetImage = "@@Patient/SetActiveSetImage",
  SetActiveSetImageId = "@@Patient/SetActiveSetImageId",
  SetIsEditSetActive = "@@Patient/SetIsEditSetActive",
  UpdateRadiographPosition = "@@Patient/UpdateRadiographPosition",
  RemoveRadiographFromSet = "@@Patient/RemoveRadiographFromSet",
  AddRadiographToSet = "@@Patient/AddRadiographToSet",
  ClearRadiographChanges = "@@Patient/ClearRadiographChanges",
  AddNewPatient = "@@Patient/AddNewPatient",
  DeletePatient = "@@Patient/DeletePatient",
}

export interface PatientList {
  id: string
  patientID: string | null
  patientUuid: string | null
  patientName: string | null
  dateOfBirth: string | null
  imageDate: string
  modalities: Record<string, number>
  statusTag?: number
  reportDate?: string
  comments?: string[]
  viewed: boolean
  status?: Status
  links: number
  xrays?: number
}

export interface PatientListResult {
  resultStatus: ResultStatus
  patientList: PatientList[]
}

export interface Patient {
  patientUuid: string | null
  patientID: string | null
  patientName: string | null
  dateOfBirth: string | null
  resultStatus?: ResultStatus
}

export interface PatientImageMeta {
  id: string
  meta: IMeta
  imageDate?: string
}

export interface ConfirmedAnnotation {
  type: AllConfirmedAnnotations
  teeth: number
}

export interface ConfirmedAnnotations {
  annotations?: ConfirmedAnnotation[]
  boneloss?: number
  status: ResultStatus
}

export interface IResultStatus {
  id: string
  status: ResultStatus
}
export interface IActivePatientListItem {
  id: string
  isRadiographSet: boolean
}
export interface ActivePatientImages {
  resultId: string | null
  report_submitted: string
  viewed: boolean
  statusTag?: number
  generalComment: string
  addedComments: { comment: string; toothName: number }[]
  imageMetadata: IMeta
  imageDate: string
  status: Status
  notes?: INote[]
  confirmedAnnotations?: ConfirmedAnnotations
}

export interface ActivePatientResult {
  resultStatus: ResultStatus
  images: ActivePatientImages[] | null
  patient: Patient | null
  links: PatientMatch[]
  radiographSets: RadiographSet[]
  activeRadiographSet: RadiographSet | null
  activePatientListItem: IActivePatientListItem | null
  isAccordionOpen: boolean
  isEditSetActive: boolean
}

export interface PatientListServerResult {
  data: PatientList[]
}

export interface RadiographSet {
  id: string
  created: string
  updated: string
  radiographs: string[]
  activeSetImage: SetPositioning | null
  template: FmxTemplate
  positions: SetPositioning[] | null
  changes: SetPositioning[] | null
  statuses: Status[]
}

export interface UpdateSet {
  id: string
  set: CreateRadiographSet
}

export type CreateRadiographSet = {
  radiographs: string[]
  template: FmxTemplate
  changes: SetPositioning[] | null
}

export interface ActivePatientServerResult {
  data: {
    images: ActivePatientImages[] | null
    patient: Patient
    links: PatientMatch[]
    radiographSets?: RadiographSet[]
  }
}

export interface IPagination {
  page: number
  perPage: number
}

export interface FilterState {
  section: string
  selected: string[]
  match: (patient: PatientList, selected: string[]) => boolean
}

export interface PatientMatch {
  chartId: string | null
  dateOfBirth: string | null
  externalId: string
  firstName: string | null
  lastName: string | null
  link: string | null
  status?: string
}

export interface PatientMetaRequest {
  links: PatientMatch[]
  patient: Patient
}

export interface MatchingPatient {
  id: string
  matchingPatientId: string | null
  linkedMeta?: PatientMatch[]
}

export interface PatientListSorting {
  key: string
  sortDirection: string
}

// Status enum values to be camelCase as it is used with translations
export enum Status {
  All = "all",
  New = "new",
  Confirmed = "confirmed",
  Viewed = "viewed",
  Uploaded = "uploaded",
}

export interface SubtypeCounts {
  subtype: string
  count: number
}

const rotations = [0, 90, 180, 270] as const
type Rotation = (typeof rotations)[number]

interface IImageData {
  id: number
  kind: Kind
  src: string
  flip?: boolean
  rotate?: Rotation
  thumbnailImageWidth: number
  thumbnailImageHeight: number
  dateOfRadiograph: Date
  dateOfReport: Date
  notes: string
  imageId: string
}

export interface IActiveSetData {
  radiographDates: string[]
  statuses: Status[]
  created: string
  id: string
  radiographs: string[]
  updated: string
  activeSetImage: SetPositioning | null
}

export interface IPatientFileItem {
  imageDate: Date
  imageData: IImageData[]
}

export type ModalityFilter = Kind | "ALL" | "SET"

export type SortedItems = {
  date: string
  id: string | null
  isRadiographSet: boolean
}

export type SetPositioning = {
  resultId: string
  position: number
}

export type UpdatedSetPosition = {
  currentRadiograph: SetPositioning
  newPosition: number
  updateActiveSetImage?: boolean
}

export type RemoveRadiograph = { id: string; isAddingRadiographToSet: boolean }

export enum FmxTemplate {
  Fmx14 = "fmx-14",
  Fmx18 = "fmx-18",
}

export type ExtractedSet = (IActiveSetData & RadiographSet) | null
