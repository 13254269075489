import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/modal"
import { HelpModalEntry, TranslationInput } from "../types/modalTypes"

export enum Modals {
  SelectRadiograph = "SelectRadiograph",
  RotateXrayImage = "RotateXrayImage",
  ChangeImageMeta = "ChangeImageMeta",
  ResetPredictions = "ResetPredictions",
  ReanalyseAlert = "ReanalyseAlert",
  ToothBasedPeriAlert = "ToothBasedPeriAlert",
  RotateOptionsModal = "RotateOptionsModal",
  NewAiVersionModal = "NewAiVersionModal",
  NewUserOnboardingModal = "NewUserOnboardingModal",
  NoSubscriptionModal = "NoSubscriptionModal",
  ExtendedReportModal = "ExtendedReportModal",
  BonelossOnlyUpsellModal = "BonelossOnlyUpsellModal",
  FaqModal = "FaqModal",
  UnsavedChanges = "UnsavedChanges",
  UploadImage = "UploadImage",
  UploadConflict = "UploadConflict",
  FullStandaloneExperience = "FullStandaloneExperience",
  AddNewPatient = "AddNewPatient",
  ViewMetadata = "ViewMetadata",
  AddDateOfRadiograph = "AddDateOfRadiograph",
}

type ModalState = Readonly<{
  openedModal: Modals | null
  currentFaqTab: HelpModalEntry
  newAIVersionModalShownIds: string[]
  navigatingUrl: string | null
  snapshotWarning: TranslationInput | null
}>

const initialState: ModalState = {
  openedModal: null,
  currentFaqTab: HelpModalEntry.Legend,
  newAIVersionModalShownIds: [],
  navigatingUrl: null,
  snapshotWarning: null,
}

type ModalActions = ActionType<typeof actions>

export default (state = initialState, action: ModalActions): ModalState => {
  switch (action.type) {
    case getType(actions.openModal):
      return {
        ...state,
        ...(!action.payload
          ? {
              openedModal: null,
              currentFaqTab: HelpModalEntry.Legend,
              navigatingUrl: null,
            }
          : { ...action.payload }),
      }

    case getType(actions.closeModal):
      return {
        ...state,
        openedModal: null,
        navigatingUrl: null,
      }
    case getType(actions.setNewAIVersionModalShownIds):
      return {
        ...state,
        newAIVersionModalShownIds: [
          ...state.newAIVersionModalShownIds,
          action.payload,
        ],
      }

    case getType(actions.setSnapshotWarning):
      return {
        ...state,
        snapshotWarning: action.payload,
      }

    default:
      return state
  }
}
