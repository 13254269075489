import { RootState } from "core/store/configureStore"

export const getCurrentFmx = (state: RootState) => state.fullMouth.radiographSet
export const getCurrentFmxSet = (state: RootState) =>
  state.fullMouth.radiographSet.set
export const getCurrentFmxSetResult = (state: RootState) =>
  state.fullMouth.radiographSet
export const getFullMouthImages = (state: RootState) =>
  state.fullMouth.fullMouthImages
export const getActiveFullMouthImage = (state: RootState) =>
  state.fullMouth.activeFullMouthImage
export const getFullMouthResults = (state: RootState) =>
  state.fullMouth.fullMouthResults
