import { Theme } from "library/common/actions/user"
import {
  AnnotationName,
  RestorationSubtype,
} from "library/common/types/adjustmentTypes"
import { baseURL } from "./fetch"

export const DEFAULT_THEME =
  (process.env.REACT_APP_DEFAULT_THEME as Theme) || Theme.dentalxrai

export const WHATS_NEW_VERSION = DEFAULT_THEME == Theme.dentalxrai ? "3" : "2"
export const maximumAllowedUploadImages = 22

export const DefaultCoachmarks = {
  cariesPro: 0,
  bonelossPro: 0,
  calculusXrayImage: 0,
  calculusToothMap: 0,
  calculusEditView: 0,
  cariesOnOpg: 0,
  autoCariesPro: 0,
}

export const SUBTYPE_RGB = {
  bridges: {
    red: 110,
    green: 229,
    blue: 255,
  },
  crowns: {
    red: 14,
    green: 180,
    blue: 217,
  },
}

export const BONELOSS_COLORS = {
  low: "#d1d1d1",
  medium: "#ffba66",
  high: "#ff8080",
}

export const MONO_BONELOSS_COLOR = "#8a80ff"

// These subtypes cannot exist together with crowns or implants
const BASE_DISABLING = [
  AnnotationName.apical,
  AnnotationName.caries,
  RestorationSubtype.fillings,
  AnnotationName.impacted,
  AnnotationName.nervus,
  RestorationSubtype.roots,
]

export const DISABLING_CASES = {
  calculus: [RestorationSubtype.bridges],
  crowns: [RestorationSubtype.bridges],
  apical: [RestorationSubtype.implants, RestorationSubtype.bridges],
  caries: [RestorationSubtype.implants, RestorationSubtype.bridges],
  fillings: [RestorationSubtype.implants, RestorationSubtype.bridges],
  impacted: [RestorationSubtype.implants, RestorationSubtype.bridges],
  nervus: [RestorationSubtype.implants, RestorationSubtype.bridges],
  roots: [RestorationSubtype.implants, RestorationSubtype.bridges],
  implants: BASE_DISABLING.concat(RestorationSubtype.bridges),
  bridges: BASE_DISABLING.concat(
    AnnotationName.calculus,
    RestorationSubtype.crowns,
    RestorationSubtype.implants
  ),
}

export const CARIES_OPACITY = 0.75
export const TOOTH_MAP_POSITION_ASPECT_RATIO = 1.85
export const SCREEN_MIN_WIDTH = 1024
export const SCREEN_MIN_HEIGHT = 600

export const DEVICE_ID = process.env.REACT_APP_DEVICE_ID || "4260739410006"
export const PRODUCT_NAME = process.env.REACT_APP_TITLE
export const IS_STORYBOOK = process.env.STORYBOOK // Provided by Storybook

export const DETECT_BROWSER_LANGUAGE =
  !!process.env.REACT_APP_DETECT_BROWSER_LANGUAGE

export const SUPPORTED_LANGUAGES =
  process.env.REACT_APP_SUPPORTED_LANGUAGES?.split(",") || ["en"]

// TODO: maybe move these to the feature reducer in redux, like SHOW_ITERO_LOGIN
export const SHOW_IDS_LOGIN = !!process.env.REACT_APP_SHOW_IDS_LOGIN
export const SHOW_AUTH0_LOGIN = !!process.env.REACT_APP_SHOW_AUTH0_LOGIN

export const RENEW_SUBSCRIPTION_URL =
  process.env.REACT_APP_RENEW_SUBSCRIPTION_URL
export const RENEW_SUBSCRIPTION_URL_AUTH0 =
  process.env.REACT_APP_RENEW_SUBSCRIPTION_URL_AUTH0
export const ADA_SETTINGS = JSON.parse(
  process.env.REACT_APP_ADA_SETTINGS || "null"
)

export const ANNOTATION_HEX_COLORS = {
  caries: "#FF2800",
  apical: "#FF8C00",
  calculus: "#ffec00",
  bridges: "#6EE5FF",
  crowns: "#12B3D9",
  fillings: "#355EF2",
  roots: "#8a80ff",
  annotate: "#FFFFFF",
  select: "#FFFFFF",
  none: "#525252",
  nervus: "#B7F63A",
  impacted: "#ff00ed",
  implant: "#6fa6ff",
  boneloss: "#8a80ff",
}

export const baseImageUrl =
  IS_STORYBOOK || !baseURL
    ? "https://bitium-develop.dentalxr.ai/v2/api" // Used for storybook
    : baseURL
