import { createAction } from "typesafe-actions"
import { ResultStatus } from "../types/dataStructureTypes"
import {
  PatientTypes,
  FilterState,
  ActivePatientResult,
  PatientListResult,
  Patient,
  PatientMatch,
  MatchingPatient,
  PatientListSorting,
  RadiographSet,
  ConfirmedAnnotations,
  IActivePatientListItem,
  SetPositioning,
  UpdatedSetPosition,
  PatientImageMeta,
  RemoveRadiograph,
} from "../types/patientTypes"

export const getPatientsFromServer = createAction(
  PatientTypes.GetPatientsFromServer
)()
export const getExternalPatient = createAction(
  PatientTypes.GetExternalPatient
)<string>()
export const setPatientListResult = createAction(
  PatientTypes.SetPatientListResult
)<PatientListResult>()
export const setPatientListResultStatus = createAction(
  PatientTypes.SetPatientListResultStatus
)<ResultStatus>()
export const setActivePatientResultStatus = createAction(
  PatientTypes.SetActivePatientResultStatus
)<ResultStatus>()
export const setPatientResultStatus = createAction(
  PatientTypes.SetPatientResultStatus
)<ResultStatus>()
export const setConfirmedAnnotations = createAction(
  PatientTypes.SetConfirmedAnnotations
)<ConfirmedAnnotations>()
export const getConfirmedAnnotations = createAction(
  PatientTypes.GetConfirmedAnnotations
)<string>()
export const setActivePatientResult = createAction(
  PatientTypes.SetActivePatientResult
)<ActivePatientResult>()
export const setActivePatientListItem = createAction(
  PatientTypes.SetActivePatientListItem
)<IActivePatientListItem | null>()
export const requestPatient = createAction(
  PatientTypes.RequestPatient
)<string>()
export const deleteActivePatientXray = createAction(
  PatientTypes.DeleteActivePatientXray
)<string>()
export const setInitialState = createAction(PatientTypes.SetInitialState)()
export const setFilterValues = createAction(PatientTypes.SetFilterValues)<
  FilterState[]
>()
export const setIsPatientMatchingMode = createAction(
  PatientTypes.SetIsPatientMatchingMode
)<boolean>()
export const clearActivePatientResult = createAction(
  PatientTypes.ClearActivePatientResult
)()
export const overwritePatient = createAction(
  PatientTypes.OverwritePatient
)<Patient>()
export const addNewPatient = createAction(PatientTypes.AddNewPatient)<Patient>()
export const deletePatient = createAction(PatientTypes.DeletePatient)<string>()

export const setPatientMatch = createAction(
  PatientTypes.SetPatientMatch
)<PatientMatch | null>()
export const setExternalPatientContext = createAction(
  PatientTypes.SetExternalPatientContext
)<PatientMatch | null>()
export const linkPatient = createAction(
  PatientTypes.LinkPatient
)<MatchingPatient>()
export const relinkPatient = createAction(
  PatientTypes.RelinkPatient
)<MatchingPatient>()
export const setPatientListSorting = createAction(
  PatientTypes.SetPatientListSorting
)<PatientListSorting>()
export const setActiveRadiographSet = createAction(
  PatientTypes.SetActiveRadiographSet
)<RadiographSet | null>()
export const replaceRadiographSetImage = createAction(
  PatientTypes.ReplaceRadiographSetImage
)<SetPositioning>()
export const setPatientMetadata = createAction(
  PatientTypes.SetPatientMetadata
)<Patient>()
export const setPatientImageMeta = createAction(
  PatientTypes.SetPatientImageMetadata
)<PatientImageMeta>()
export const setActiveSetImage = createAction(
  PatientTypes.SetActiveSetImage
)<SetPositioning | null>()
export const setActiveSetImageId = createAction(
  PatientTypes.SetActiveSetImageId
)<string>()
export const setIsEditSetActive = createAction(
  PatientTypes.SetIsEditSetActive
)<boolean>()
export const toggleAccordion = createAction(
  PatientTypes.ToggleAccordion
)<boolean>()
export const updateRadiographPosition = createAction(
  PatientTypes.UpdateRadiographPosition
)<UpdatedSetPosition>()
export const removeRadiographFromSet = createAction(
  PatientTypes.RemoveRadiographFromSet
)<RemoveRadiograph>()
export const addRadiographToSet = createAction(
  PatientTypes.AddRadiographToSet
)<string>()
export const clearRadiographChanges = createAction(
  PatientTypes.ClearRadiographChanges
)()
